<template>
	<v-card class="mt-3">
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialog" persistent scrollable>
			<v-card outlined max-width="575">
				<v-card-title class="text-h5">Adicionar usuário</v-card-title>
				<v-form v-if="buscarCpf">
					<v-card-text>
						<v-divider></v-divider>
						<v-row>
							<v-col cols="12" class="mt-10">
								<v-text-field
									v-mask="'###.###.###-##'"
									:disabled="carregando"
									v-model="cpf"
									label="* CPF"
									dense
									@keydown.enter="usuarioCpf()"
								/>
							</v-col>
						</v-row>
						<v-alert
							v-if="erro"
							dismissible
							icon="mdi-alert-circle-outline"
							close-icon="mdi-close"
							type="error"
							class="mb-0"
						>{{erro}}</v-alert>
					</v-card-text>
					<v-card-actions>
						<span class="text-caption mr-5 mr-sm-10">* Campos obrigatórios</span>
						<v-spacer></v-spacer>
						<v-btn
							:disabled="carregando"
							color="primary"
							text
							@click="dialog = false, buscarCpf = true, listar(0)"
						>Fechar</v-btn>
						<v-btn :disabled="carregando" color="primary" text @click="usuarioCpf()">Buscar</v-btn>
					</v-card-actions>
				</v-form>
				<v-form v-else-if="usuarioAutorizado.existe">
					<v-card-text>
						<v-divider></v-divider>
						<v-row>
							<v-col cols="12" class="mt-5 mb-n8">
								<v-text-field
									disabled
									label="Usuário"
									:value="usuarioAutorizado.usuario.usuario"
									required
								>Usuário</v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field disabled label="Nome" :value="usuarioAutorizado.usuario.nome" required>Nome</v-text-field>
							</v-col>
						</v-row>
						<v-alert
							v-if="erro"
							dismissible
							icon="mdi-alert-circle-outline"
							close-icon="mdi-close"
							type="error"
							class="mb-0"
						>{{erro}}</v-alert>
						<v-alert v-if="msg" icon="mdi-check" type="success" class="mb-0">{{msg}}</v-alert>
					</v-card-text>
					<v-card-actions>
						<span class="text-caption mr-5 mr-sm-10">* Campos obrigatórios</span>
						<v-spacer></v-spacer>
						<v-btn
							:disabled="carregando"
							color="primary"
							text
							@click="dialog = false, buscarCpf = true, listar(0)"
						>Fechar</v-btn>
						<v-btn
							v-if="!usuarioAutorizado.usuario.vinculadocliente && !vinculado"
							:disabled="carregando"
							color="primary"
							text
							@click="vincularUsuario(usuarioAutorizado.usuario.idusuario)"
						>Vincular</v-btn>
					</v-card-actions>
				</v-form>
				<v-form v-else ref="form" v-model="valid" lazy-validation>
					<v-card-text>
						<v-divider></v-divider>
						<v-row>
							<v-col cols="12" class="mt-5 mb-n8">
								<v-text-field disabled label="CPF" :value="cpf | VMask('###.###.###-##')" required></v-text-field>
							</v-col>
							<v-col cols="12" class="mb-n8">
								<v-text-field
									:rules="usuarioRegra"
									:disabled="carregando"
									label="* Usuário"
									v-model="usuario"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" class="mb-n8">
								<v-text-field
									:rules="nomeRegra"
									:disabled="carregando"
									label="* Nome completo"
									v-model="nome"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" class="mb-n8">
								<v-text-field :disabled="carregando" label="E-mail" v-model="email" type="email"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-if="telefone.length <= 14"
									v-mask="'(##) ####-####'"
									:rules="telefoneRegra"
									:disabled="carregando"
									label="* Telefone"
									v-model="telefone"
									required
								></v-text-field>
								<v-text-field
									v-else
									v-mask="'(##) #####-####'"
									:rules="telefoneRegra"
									:disabled="carregando"
									label="* Telefone"
									v-model="telefone"
									required
								></v-text-field>
							</v-col>
						</v-row>
						<v-alert
							v-if="erro"
							icon="mdi-alert-circle-outline"
							type="error"
							class="mb-0 mt-1"
							v-html="erro"
						/>
					</v-card-text>
					<v-card-actions>
						<span class="pl-3 text-caption">* Campos obrigatórios</span>
						<v-spacer></v-spacer>
						<v-btn
							:disabled="carregando"
							color="primary"
							text
							@click="dialog = false, buscarCpf = true, listar(0)"
						>Fechar</v-btn>
						<v-btn
							v-if="!msg"
							:disabled="carregando"
							color="primary"
							text
							@click="validate(), cadastrarUsuario()"
						>Cadastrar</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col class="pr-0">
				<v-card-title class="pt-1 pr-0">Usuários autorizados</v-card-title>
			</v-col>
			<v-col v-if="permissao('cliente_inserir_usuario')" cols="auto" class="mr-2">
				<v-btn
					:disabled="carregando"
					class="mx-2 mt-1"
					color="primary"
					elevation="0"
					fab
					x-small
					@click="dialog = true, limpar()"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text class="pa-0">
			<v-simple-table>
				<template v-slot:default>
					<!--                    <thead>-->
					<!--                        <tr>-->
					<!--                            <th class="text-left">Nome</th>-->
					<!--                            <th class="text-center">Status</th>-->
					<!--                            <th class="text-center px-0"></th>-->
					<!--                        </tr>-->
					<!--                    </thead>-->
					<tbody>
						<template v-if="dados.total > 0">
							<tr v-for="(u, i) in dados.lista" :key="i">
								<td class="pr-0">
									<strong>
										<span>{{u.nome}}</span>
									</strong>
									<br />
									<span v-if="u.cpf" class="text-truncate text-caption">
										<strong>CPF:</strong>
										{{u.cpf | formataCpf}}
									</span>
								</td>
								<v-spacer />
								<!--                                <td class="text-center pl-2 pr-1">-->
								<!--                                    <v-chip-->
								<!--                                        small-->
								<!--                                        v-if="u.status"-->
								<!--                                        color="success"-->
								<!--                                        class="text-caption"-->
								<!--                                    >Ativo</v-chip>-->
								<!--                                    <v-chip v-else dark color="red" class="text-caption">Inativo</v-chip>-->
								<!--                                </td>-->
								<td class="text-end px-0 pr-3" width="45%">
									<v-btn
										:disabled="carregando"
										class="text-caption px-2"
										color="primary"
										x-small
										text
										@click="redefinirSenha(u.idusuario)"
									>Redefinir Senha</v-btn>
									<v-btn
										:disabled="carregando"
										class="text-caption px-2"
										color="primary"
										x-small
										text
										@click="removerUsuarioCliente(u.idusuario)"
									>Remover Usuário</v-btn>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="3">Nenhum registro encontrado.</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<Paginacao
				:total="dados.total"
				@atualizar="listar"
				:carregando="carregando"
				:pg="pgSelecionada"
			/>
		</v-card-text>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Paginacao from "../Widgets/Paginacao";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "ClienteListaUsuario",
	props: ["idcliente", "pgLimitGet"],
	components: { Paginacao, CardDialog },
	data: () => ({
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		dialog: false,
		carregando: false,
		valid: true,
		dados: {},
		cpf: "",
		usuario: "",
		usuarioRegra: [(v) => !!v || "Campo obrigatório"],
		nome: "",
		nomeRegra: [(v) => !!v || "Campo obrigatório"],
		telefone: "",
		telefoneRegra: [(v) => !!v || "Campo obrigatório"],
		email: "",
		erro: "",
		msg: "",
		vinculado: false,
		buscarCpf: true,
		usuarioAutorizado: {},
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit"]),
	},
	methods: {
		limpar() {
			this.cpf = "";
			this.usuario = "";
			this.nome = "";
			this.telefone = "";
			this.email = "";
			this.erro = "";
			this.msg = "";
			this.vinculado = false;
		},
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}usuario/listaclientesvinculados`, {
					limit: this.pgLimitGet || this.pgLimit,
					offset: this.pgLimitGet * pg || this.pgLimit * pg,
					idcliente: parseInt(this.idcliente) || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
				});
		},
		redefinirSenha(idusuario) {
			return axios
				.post(`${this.backendUrl}usuario/resetuserpassword`, {
					idusuario: parseInt(idusuario),
				})
				.then((res) => {
					if (res.data) {
						this.dialogErro.title = "Senha redefinida";
						this.dialogErro.msg =
							"Senha redefinida com sucesso! Próximo acesso usar CPF!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Não foi possível redefinir a senha do usuário!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível redefinir a senha do usuário!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		removerUsuarioCliente(idusuario) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}usuario/excluirusuariocliente`, {
					idusuario: parseInt(idusuario),
					idcliente: parseInt(this.idcliente),
				})
				.then(() => {
					this.carregando = false;
					this.listar(0);
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		usuarioCpf() {
			this.erro = "";
			let cpf = this.cpf.replaceAll(".", "").replaceAll("-", "");
			if (cpf.length != 11) {
				return (this.erro = "CPF inválido.");
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}usuario/usuariocpf`, {
					cpf: cpf,
					idcliente: parseInt(this.idcliente),
				})
				.then((res) => {
					this.usuarioAutorizado = res.data;
					this.buscarCpf = false;
					this.carregando = false;
					if (this.usuarioAutorizado.usuario.vinculadocliente) {
						this.erro = "Usuário já está vinculado ao cliente.";
						this.msg = "";
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.erro = this.erroFormatar(e);
				});
		},
		vincularUsuario(idusuario) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}usuario/vinculausuariocliente`, {
					idusuario: parseInt(idusuario),
					idcliente: parseInt(this.idcliente),
				})
				.then((res) => {
					this.carregando = false;
					if (res) {
						this.vinculado = true;
						this.msg = "Usuário adicinado ao cliente com sucesso.";
						this.erro = "";
					} else {
						this.erro =
							"Falha ao adicionar usuário ao cliente, entre em contato com o suporte.";
						this.msg = "";
					}
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		validate() {
			this.$refs.form.validate();
		},
		cadastrarUsuario() {
			this.carregando = true;
			let cpf = this.cpf.replaceAll(".", "").replaceAll("-", "");
			return axios
				.post(`${this.backendUrl}usuario/cadastrausuario`, {
					cpf: cpf,
					usuario: this.usuario,
					nome: this.nome,
					telefone: this.telefone,
					email: this.email,
					idcliente: parseInt(this.idcliente),
				})
				.then((res) => {
					this.carregando = false;
					if (res) {
						this.msg = "Usuário cadastrado com sucesso.";
						this.erro = "";
						this.usuarioCpf();
					} else {
						this.erro = res.erro;
						this.msg = "";
					}
				})
				.catch((e) => {
					this.erro = this.erroFormatar(e);
					this.carregando = false;
				});
		},
	},
	created() {
		this.listar(0);
	},
};
</script>

<style scoped>
</style>