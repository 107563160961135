<template>
    <v-card class="mt-3">
        <v-snackbar :color="snackbar.color" v-model="snackbar.status" :timeout="snackbar.timeout" dark>
            <span class="font-weight-bold">{{snackbar.text}}</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    class="font-weight-bold"
                    color="light-green lighten-5"
                    text
                    v-bind="attrs"
                    @click="snackbar.status = false"
                >Fechar</v-btn>
            </template>
        </v-snackbar>
        <v-dialog v-model="dialogErro.status" persistent>
            <CardDialog :dialog="dialogErro" />
        </v-dialog>
        <v-dialog v-model="dialogConfirmacao.status" persistent>
            <v-card class="pa-5">
                <v-card-title class="text-h4">
                    <span class="mx-auto">{{dialogConfirmacao.title}}</span>
                </v-card-title>
                <div class="text-center py-3">
                    <v-icon size="100" color="error">{{dialogConfirmacao.icon}}</v-icon>
                </div>
                <v-card-text class="text-h6 text-center">{{dialogConfirmacao.msg}}</v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" elevation="0" class="px-10" @click="dialogConfirmacao.status = false">Não</v-btn>
                    <v-btn color="error" elevation="0" class="px-10" @click="dialogConfirmacao.status = false, deletaLocal(idlocal)">Sim</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" persistent scrollable>
            <v-card outlined max-width="700">
                <v-card-title class="pl-5">{{cadastrando ? 'Cadastro de Local de Entrega' : 'Alteração de Local de Entrega'}}</v-card-title>
                <v-divider/>
                <v-card-text class="py-5 px-5">
                    <v-row>
                        <v-col cols="12" xl="8" lg="8" md="8" sm="8">
                            <v-text-field
                                class="mb-n5"
                                v-model="camposlocal.nome"
                                label="*Nome"
                                dense
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                            <v-text-field
                                label="*CEP"
                                class="mb-n5"
                                v-model="camposlocal.cepentrega"
                                required
                                dense
                                :disabled="carregando"
                                @change="listarCeps()"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="9" lg="9" md="9" sm="9">
                            <v-text-field
                                class="mb-n5"
                                :disabled="carregando"
                                v-model="camposlocal.endereco"
                                label="*Endereço"
                                dense
                                outlined
                            />
                        </v-col>
                        <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                            <v-text-field
                                class="mb-n5"
                                :disabled="carregando"
                                v-model="camposlocal.numero"
                                label="*Número"
                                dense
                                outlined
                            />
                        </v-col>
                        <v-col cols="8" xl="6" lg="6" md="6" sm="6">
                            <v-text-field
                                class="mb-n5"
                                :disabled="carregando"
                                v-model="camposlocal.bairroentrega"
                                label="*Bairro"
                                dense
                                outlined
                            />
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                            <v-autocomplete
                                class="mb-n5"
                                v-model="camposlocal.idregiao"
                                :items="regioes"
                                :loading="carregando"
                                label="Região"
                                item-text="regiao"
                                item-value="idregiao"
                                dense
                                required
                                outlined
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                class="mb-n5"
                                :disabled="carregando"
                                v-model="camposlocal.complemento"
                                label="Complemento"
                                dense
                                outlined
                            />
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                            <v-text-field
                                class="mb-n5"
                                :disabled="carregando"
                                label="*CNPJ/CPF"
                                v-model="camposlocal.cnpjcpf"
                                dense
                                outlined
                            />
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                            <v-text-field
                                class="mb-n5"
                                v-model="camposlocal.ie"
                                label="*Inscrição Estadual"
                                dense
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="9" xl="9" lg="9" md="9" sm="9">
                            <v-text-field
                                class="mb-n5"
                                v-model="camposlocal.cidade"
                                label="*Cidade"
                                dense
                                disabled
                                outlined
                            />
                        </v-col>
                        <v-col cols="3" xl="3" lg="3" md="3" sm="3">
                            <v-text-field
                                class="mb-n5"
                                v-model="camposlocal.uf"
                                label="*UF"
                                dense
                                disabled
                                outlined
                            />
                        </v-col>
                    </v-row>
                    <v-alert dismissible class="mb-n4 mt-4" type="error" v-if="erro" v-html="erro"/>
                    <v-progress-linear indeterminate absolute :active="carregando" />
                </v-card-text>
                <v-card-actions class="pl-5">
                    <span class="text-caption">* Campos obrigatórios</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="carregando"
                        color="primary"
                        text
                        @click="limpar()"
                    >Fechar</v-btn>
                    <v-btn
                        v-if="cadastrando"
                        :disabled="carregando"
                        color="primary"
                        text
                        @click="cadastraLocal()"
                    >Cadastrar</v-btn>
                    <v-btn
                        v-else
                        :disabled="carregando"
                        color="primary"
                        text
                        @click="atualizaLocal()"
                    >Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card-title class="pt-1">Locais de Entrega</v-card-title>
            </v-col>
            <v-col cols="auto" class="mr-2">
                <v-tooltip  color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :disabled="carregando"
                            class="mx-2 mt-1"
                            color="primary"
                            elevation="0"
                            fab
                            x-small
                            v-on="on"
                            v-bind="attrs"
                            @click="listaPreDados()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span class="text-truncate"> Adicionar Local de Entrega</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-divider />
        <v-card-text class="pa-0">
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <template height="500" v-if="dados.enderecoentrega">
                            <tr v-for="(t, i) in dados.enderecoentrega" :key="i">
                                <td class="pl-4 py-2 pr-2">
                                    <strong><span class="d-inline">{{t.nome}}</span></strong>
                                    <br />
                                    <small><strong>CNPJ/CPF: </strong>{{t.cnpjcpf}}</small><br/>
                                    <small><strong>Insc. Estadual: </strong>{{t.ie}}</small><br/>
                                    <p class="ma-0"><small><strong>Endereço: </strong>{{t.endereco}}, N°{{t.numero}}</small></p>
                                    <small><strong>Cidade: </strong>{{t.cidade}} - {{t.uf}}</small>
                                </td>
                                <td class="pa-0 pr-3">
                                    <v-btn
                                        :disabled="carregando"
                                        class="float-end mb-1 ml-1"
                                        color="primary"
                                        elevation="0"
                                        fab
                                        x-small
                                        width="25px"
                                        height="25px"
                                        @click="listaLocal(t)"
                                    >
                                        <v-icon x-small>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn
                                        :disabled="carregando"
                                        class="float-end"
                                        color="error"
                                        elevation="0"
                                        fab
                                        x-small
                                        @click="confirmarExclusao(), idlocal = t.idlocalentrega"
                                        width="25px"
                                        height="25px"
                                    >
                                        <v-icon x-small>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center" colspan="3">Nenhum registro encontrado.</td>
                            </tr>
                        </template>
                        <v-progress-linear indeterminate absolute :active="carregando" />
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";

export default {
    name: "ClienteListaUsuario",
    props: ["idcliente", "pgLimitGet", "dados"],
    components: { CardDialog },
    data: () => ({
        cadastrando: false,
        erro : null,
        snackbar: { status: false, timeout: 5000 },
        camposlocal:{
            cidade : '',
            cepentrega: '',
        },
        idlocal: '',
        dialog: false,
        ceps: { lista: [] },
        regioes: [],

        dialogConfirmacao: { status: false } ,

        dialogErro: {
            status: false,
            title: "",
            msg: "",
            icon: "",
            iconColor: "",
        },
        carregando: false,
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit"]),
    },
    methods: {
        maskcnpjcpf() {
            if(this.camposlocal.cnpjcpf) {
                alert(2)
                return this.camposlocal.cnpjcpf.length <= 14 ? '###.###.###-##' : '##.###.###/####-##'
            } else {
                alert(1)
                return this.camposlocal.cnpjcpf
            }
        },

        listarCeps() {
            this.ceps.lista = [];
            return axios
                .post(`${this.backendUrl}endereco/cep/get`, {
                    cep: this.camposlocal.cepentrega.replace('-',''),
                    limit: 99999,
                    offset: 0,
                })
                .then((res) => {
                    if (res.data.lista != null) {
                        this.ceps = res.data;
                    }
                    this.camposlocal.cidade = res.data.cidade.trim()
                    this.camposlocal.uf = res.data.uf.trim()
                    this.camposlocal.endereco = res.data.endereco.trim()
                    this.camposlocal.bairroentrega = res.data.bairro.trim()
                    this.camposlocal.idcidade = res.data.idcidade
                })
                .catch(() => {
                    this.camposlocal.cidade = ""
                    this.camposlocal.bairroentrega = ""
                    this.camposlocal.uf = ""
                    this.camposlocal.endereco = ""
                    this.camposlocal.idcidade = 0
                });
        },

        listarRegioes() {
            return axios
                .post(`${this.backendUrl}regioes/listar`)
                .then((res) => {
                    this.regioes = res.data
                });
        },

        limpar() {
            this.erro = null
            this.camposlocal.idClifor = null
            this.camposlocal.idregiao = null
            this.camposlocal.cepentrega = null
            this.camposlocal.idLocalEntrega = ''
            this.camposlocal.nome = ''
            this.camposlocal.bairroentrega = ''
            this.camposlocal.idcidade = ''
            this.camposlocal.cidade = ''
            this.camposlocal.uf = ''
            this.camposlocal.cnpjcpf= ''
            this.camposlocal.ie = ''
            this.camposlocal.complemento = ''
            this.camposlocal.endereco = ''
            this.camposlocal.numero = ''
            this.dialog = false
        },

        listaPreDados() {
            this.cadastrando= true
            let pre = JSON.parse(JSON.stringify(this.dados));
            this.camposlocal.cnpjcpf = pre.cpfcnpj
            this.camposlocal.nome = pre.nome
            this.camposlocal.ie = pre.ie
            this.camposlocal.idclifor = pre.idcliente
            this.dialog = true
        },

        listaLocal(local) {
            this.cadastrando= false
            let locais = JSON.parse(JSON.stringify(local));
            this.camposlocal = locais
            //console.log(this.camposlocal)
            this.dialog = true
        },

        atualizaLocal() {
            this.carregando = true
            return axios
                .post(`${this.backendUrl}endereco/entrega/atualizar`, {
                    idlocalentrega: this.camposlocal.idlocalentrega,
                    idclifor:this.camposlocal.idclifor,
                    nome: this.camposlocal.nome,
                    bairroentrega: this.camposlocal.bairroentrega,
                    cepentrega: this.camposlocal.cepentrega.replaceAll('-',''),
                    idcidade: this.camposlocal.idcidade,
                    cidade: this.camposlocal.cidade,
                    idregiao: this.camposlocal.idregiao,
                    uf: this.camposlocal.uf,
                    cnpjcpf: this.camposlocal.cnpjcpf.replaceAll('-','').replaceAll('.',''),
                    ie: this.camposlocal.ie,
                    complemento: this.camposlocal.complemento,
                    endereco: this.camposlocal.endereco,
                    numero: this.camposlocal.numero,
                })
                .then(() => {
                    this.$emit("atualizar")
                    this.erro = null
                    this.carregando = false
                    this.dialog = false
                    this.limpar()
                    this.snackbar.text = "Local de entrega atualizada com sucesso!";
                    this.snackbar.status = true
                    this.snackbar.color = 'light-green darken-3'
                })
                .catch((r) => {
                    this.erro = r.response.data.erro.replaceAll(';', '<br/>')
                    this.carregando = false
                })
        },

        cadastraLocal() {
            this.carregando = true
            return axios
                .post(`${this.backendUrl}endereco/entrega/cadastrar`, {
                    idclifor: this.camposlocal.idclifor,
                    idlocalentrega: this.camposlocal.idlocalentrega,
                    nome: this.camposlocal.nome,
                    bairroentrega: this.camposlocal.bairroentrega,
                    cepentrega: this.camposlocal.cepentrega.replaceAll('-',''),
                    idcidade: this.camposlocal.idcidade,
                    cidade: this.camposlocal.cidade,
                    idregiao: this.camposlocal.idregiao,
                    uf: this.camposlocal.uf,
                    cnpjcpf: this.camposlocal.cnpjcpf.replaceAll('-','').replaceAll('.',''),
                    ie: this.camposlocal.ie,
                    complemento: this.camposlocal.complemento,
                    endereco: this.camposlocal.endereco,
                    numero: this.camposlocal.numero,
                })
                .then(() => {
                    this.$emit("atualizar")
                    this.erro = null
                    this.carregando = false
                    this.dialog = false
                    this.limpar()
                    this.snackbar.text = " Local de entrega cadastrado com sucesso!";
                    this.snackbar.status = true
                    this.snackbar.color = 'light-green darken-3'
                })
                .catch((r) => {
                    this.erro = r.response.data.erro.replaceAll(';', '<br/>')
                    this.carregando = false
                })
        },

        deletaLocal(idlocal) {
            this.carregando = true
            return axios
                .post(`${this.backendUrl}endereco/entrega/excluir`, {
                    IdLocalEntrega : idlocal,
                    idclifor: this.dados.idcliente
                })
                .then((res) => {
                    if (res.data) {
                        console.log(this.dados)
                        this.$emit("atualizar")
                        this.idlocal = ''
                        this.snackbar.text = "Local de entrega excluído com sucesso!";
                        this.snackbar.status = true;
                        this.snackbar.color = 'light-green darken-3'
                        this.carregando = false
                    } else {
                        this.snackbar.text = "Não é possível excluir local de entrega selecionado";
                        this.snackbar.status = true;
                        this.snackbar.color = 'red'
                        this.carregando = false
                    }
                })
                .catch(() => {
                    this.snackbar.text = "Não é possível excluir local de entrega selecionado";
                    this.snackbar.status = true;
                    this.snackbar.color = 'red'
                    this.carregando = false
                })
        },

        confirmarExclusao(){
            this.dialogConfirmacao.status = true
            this.dialogConfirmacao.icon = 'mdi-alert'
            this.dialogConfirmacao.title = 'Exclusão'
            this.dialogConfirmacao.msg = 'Deseja excluir local de entrega selecionado?'
        },

        async init() {
            await this.listarRegioes();
        },
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>
</style>