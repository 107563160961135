<template>
	<v-row>
		<v-col cols="12" md="4">
			<v-row>
				<v-col cols="12">
					<v-card class="mx-auto">
						<v-card-title>
							Informações
							<v-spacer />
							<ClienteAlterar :idcliente="idcliente" @atualizar="get" />
						</v-card-title>
						<v-divider />
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-text>
							<div class="my-3">
								<v-icon class="mr-2">mdi-identifier</v-icon>
								<span class="font-weight-bold">ID:</span>
								{{dados.idcliente}}
							</div>
							<div class="my-3">
								<v-icon class="mr-2">mdi-account-outline</v-icon>
								<span class="font-weight-bold">Nome:</span>
								{{dados.nome}}
							</div>
							<div class="my-3" v-if="dados.endereco">
								<v-icon class="mr-2">mdi-map-marker</v-icon>
								<span class="font-weight-bold">Endereço:</span>
								{{dados.endereco.endereco}}, Nº{{dados.endereco.numero}}
							</div>
							<div class="my-3" v-if="dados.endereco">
								<v-icon class="mr-2">mdi-city</v-icon>
								<span class="font-weight-bold">Cidade:</span>
								{{dados.endereco.cidade}}-{{dados.endereco.uf}}
							</div>
							<!--							<div class="my-3">-->
							<!--								<v-icon class="mr-2">mdi-phone</v-icon>-->
							<!--								<span class="font-weight-bold">Contato:</span>-->
							<!--								{{dados.contato | formataContato}}-->
							<!--							</div>-->
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<ClienteListaLocaisEntrega :dados="dados" @atualizar="get" />
				</v-col>
				<v-col cols="12">
					<ClienteListaUsuario :pgLimitGet="3" :idcliente="idcliente" />
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" md="8">
			<v-row>
				<v-col>
					<Table
						:pgLimitGet="5"
						backendDirectory="pedido/listar"
						:busca="{
                            idcliente: parseInt(idcliente),
                            busca: pedidos.busca || null
                        }"
						:filter="true"
						:headers="{
                            idpedido: {nome: 'ID', tipo: 'link', link: '../../venda/pedido/get/'},
                            filial: {nome: 'Filial', tipo: 'texto'},
                            valortotal: {nome: 'Valor', tipo: 'dinheiro'},
                            dtpedido: {nome: 'Data do pedido', tipo: 'texto'},
                        }"
					>
						<v-col>Pedidos</v-col>
						<v-col>
							<v-text-field :disabled="carregando" v-model="pedidos.busca" label="Buscar" outlined dense />
						</v-col>
					</Table>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<Table
						:pgLimitGet="5"
						backendDirectory="contrato/listar"
						:busca="{
                            idcliente: parseInt(idcliente),
                            busca: contratos.busca || null
                        }"
						:filter="true"
						:headers="{
                            idcontrato: {nome: 'ID', tipo: 'link', link: '/contrato/get/'},
                            filial: {nome: 'Filial', tipo: 'texto'},
                            dtcontrato: {nome: 'Data do pedido', tipo: 'date' },
                            status: {nome: 'Status', tipo: 'boolean', true: 'Contrato enviado', false: 'Contrato pendente'},
                        }"
					>
						<v-col>Contratos aguardando</v-col>
						<v-col>
							<v-text-field
								:disabled="carregando"
								v-model="contratos.busca"
								label="Buscar"
								outlined
								dense
							/>
						</v-col>
					</Table>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import ClienteAlterar from "../Cliente/ClienteAlterar";
import ClienteListaLocaisEntrega from "./ClienteListaLocaisEntrega";
import ClienteListaUsuario from "../Cliente/ClienteListaUsuario";
import { mapState } from "vuex";
import axios from "axios";
import Table from "../Widgets/Table.vue";

export default {
	components: {
		ClienteListaUsuario,
		Table,
		ClienteListaLocaisEntrega,
		ClienteAlterar,
	},
	name: "ClienteGet",
	props: ["idcliente"],
	data: () => ({
		carregando: false,
		pedidos: {},
		contratos: {},
		dados: {},
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}cliente/get`, {
					idcliente: parseInt(this.idcliente),
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
	},

	created() {
		this.get();
	},
};
</script>

<style scoped>
</style>